import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Line } from 'app/components/Common/Line'
import { IconLogo } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import { uniqBy } from 'lodash'
import React, { memo, useState } from 'react'

import { Filter } from './Filter'
import { Props as RoomProps, Room } from './Room'

export interface Props {
  label?: string
  languageCode: string
  rooms: RoomProps[]
  title?: string
}

export const RoomsList = memo(function RoomsList({
  label,
  languageCode,
  rooms,
  title,
}: Props) {
  if (rooms.length < 1) {
    return null
  }

  const [filteredCategory, setFilteredCategory] = useState('all')

  return (
    <Container>
      <IconLogo />

      {label ? (
        <FadeInUp>
          <Label>{label}</Label>
        </FadeInUp>
      ) : null}
      {title ? (
        <FadeInUp>
          <Title>
            {title}
            <Line />
          </Title>
        </FadeInUp>
      ) : null}

      {/* <Filters dial={5} row wrap>
        <Filter
          active={filteredCategory === 'all'}
          category="all"
          title={useVocabularyData('all', languageCode)}
          onClick={() => {
            setFilteredCategory('all')
          }}
        />
        {uniqBy(rooms, 'category').map((item, index) => {
          const category = item.category
            .replace(/[^a-zA-Z]/g, '')
            .toLocaleLowerCase()

          return category ? (
            <Filter
              active={filteredCategory === category}
              category={category}
              key={index}
              onClick={(e) => {
                setFilteredCategory(e.target.getAttribute('data-category'))
              }}
              title={item.category}
            />
          ) : undefined
        })}
      </Filters> */}

      <Rooms row wrap>
        {uniqBy(rooms, 'title').map((item, index) => {
          const category = item.category
            .replace(/[^a-zA-Z]/g, '')
            .toLocaleLowerCase()

          return (
            <Room
              className={
                filteredCategory === category || filteredCategory === 'all'
                  ? 'visible'
                  : undefined
              }
              key={index}
              {...item}
            />
          )
        })}
      </Rooms>
    </Container>
  )
})

const Container = styled.section`
  margin: -3.125rem 0 12.5rem;
  padding: 0 10.556vw;
  text-align: center;

  > svg {
    width: 4.9375rem;
    height: auto;
    fill: ${({ theme }) => theme.colors.variants.neutralDark2};
    margin-bottom: 3.25rem;
  }

  @media (max-width: 1199px) {
    margin: -1.25rem 0 7.5rem;
    padding: 0 1.875rem;

    > svg {
      width: 3.75rem;
      margin-bottom: 2.5rem;
    }
  }

  @media (max-width: 767px) {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  font-weight: 600;
  line-height: 1.25rem;
  margin-bottom: 0.9375rem;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    font-size: 1rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3rem;
  font-weight: 500;
  line-height: 4rem;

  > div {
    margin: 1.875rem auto 0;
  }

  @media (max-width: 1199px) {
    font-size: 2.25rem;
    line-height: 2.875rem;

    > div {
      margin-top: 1.25rem;
    }
  }
`

const Filters = styled(FlexBox)`
  margin: 4rem 0;

  @media (max-width: 1199px) {
    margin: 3.125rem 0 1.875rem;
  }

  @media (max-width: 1023px) {
    margin: 2.125rem 0 0;
  }
`

const Rooms = styled(FlexBox)`
  margin-right: -3.625rem;

  > a {
    width: calc(33.333% - 3.625rem);
    margin: 5rem 3.625rem 0 0;
  }

  @media (max-width: 1199px) {
    margin-right: -2.5rem;

    > a {
      width: calc(33.333% - 2.5rem);
      margin: 4.375rem 2.5rem 0 0;
    }
  }

  @media (max-width: 1023px) {
    margin-right: -1.5rem;

    > a {
      width: calc(50% - 1.5rem);
      margin-right: 1.5rem;
    }
  }

  @media (max-width: 767px) {
    margin-right: 0;

    > a {
      width: 100%;
      margin-right: 0;
    }
  }
`
